import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import UpdateAction from '../../components/actions/UpdateAction';
import { optionalParam, arrayParamBuilder, datify, formatDate, renderSortColumn } from '../../utilities/Generic.js'
import ConditionalLink from '../../components/shared/ConditionalLink';
import NoticeBox from '../../components/shared/NoticeBox';


import { CSVLink } from "react-csv";

import { faFileDownload, faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

class ExportFeedback extends Component {
  constructor(props) {
    super(props);

    this.handleFeedbackActionsPanel = this.handleFeedbackActionsPanel.bind(this);
    this.closePanel = this.closePanel.bind(this);
    this.renderSortColumn = renderSortColumn.bind(this);
  }

  closePanel() {
    this.setState({ feedbackActionsPanelToggle: false });
    this.setState({ feedbackId: null });
    this.props.history.push(
      `/exports/feedback?from=${this.props.date_from}&to=${this.props.date_to}` +
      optionalParam("question", this.props.question) +
      optionalParam("location", this.props.location) +
      optionalParam("type", this.props.type_of_person) +
      optionalParam("action_needed", this.props.action_needed) +
      optionalParam("action_statuses", this.props.action_statuses) +
      `&page=1&sort=${this.props.sort}&direction=${this.props.direction}`
    );
    this.componentDidMount();
  }

  emptyFeedback(feedbacks) {
    if (feedbacks.length === 0) {
      return (
        <div className="long-tr">
          <div className="long-td">
            { this.emptyFeedbackText() }
          </div>
        </div>
      )
    }
  }

  emptyFeedbackText() {
    const action_needed = this.props.action_needed ? " action needed" : ""
    let text = `No${action_needed} feedback to show`
    this.props.page > 1 && (text+= " on this Page")
    text+= " for this"

    let content = []
    this.props.question !== "" && content.push(" Question")
    this.props.selected_location !== "" && content.push(" Location")
    this.props.type_of_person !== "" && content.push(" Type")

    if (content.length > 0) {
      for (let index = content.length - 1; index > 0; index--) {
        content.splice(index, 0, " and")
      }

      for (const index of content) {
        text += index
      }

      text += " in this"
    }

    text += " time range"

    if (this.props.action_statuses.length > 0) {
      text += " with this action status"
     }
    text += "."

    return text
  }

  downloadCSV(feedbacks) {
    let filename = `feedback-export-${formatDate(new Date(), 0)}.csv`

    let headers = [
      { label: "Question", key: "question" },
      { label: "Answer", key: "answer" },
      { label: "Type", key: "type_of_person" },
      { label: "Date", key: "date" },
      { label: "Person", key: "person" },
      { label: "Location", key: "location" },
      { label: "Action Needed", key: "action_needed" },
      { label: "Action Status", key: "action_status" }
    ];

    if (feedbacks.length === 0) {
      feedbacks = [{[headers[0].key]: this.emptyFeedbackText()}]
    }

    return (
      <CSVLink data={feedbacks} headers={headers} filename={filename} className="edit-button download-csv">
        <div>
          <FontAwesomeIcon icon={faFileDownload} />
          <h4>Download CSV</h4>
        </div>
      </CSVLink>
    )
  }

  handleFeedbackActionsPanel(event) {
    let id = event.currentTarget.id;

    this.setState({
      feedbackId: id,
      feedbackActionsPanelToggle: true
    });
    this.props.history.push(`/exports/feedback/${id}`)
  }

  renderPageButtons(page, records, cool_feedback_paginated) {
    if (parseInt(page) === 1 && records.length < 250 && cool_feedback_paginated === false) {
      return
    }
    else {
      return (
        <div>
          <button className="button new-button" onClick={this.props.handleNextPage} disabled={(records.length < 250 && cool_feedback_paginated === false)}>Next Page</button>
          <button className="button new-button" onClick={this.props.handlePreviousPage} disabled={(this.props.page < 2)}>Previous Page</button>
        </div>
      )
    }
  }

  renderCoolFeedbackPaginationInfo(page, cool_feedback, cool_feedback_paginated, sort) {
      let modifiedDateFrom = new Date(this.props.date_from)
      modifiedDateFrom.setMonth(modifiedDateFrom.getMonth() + 3)
      modifiedDateFrom.setDate(modifiedDateFrom.getDate() - 3)

      const dateFromPastThreeMonths = modifiedDateFrom < Date.now();

    if (dateFromPastThreeMonths && (page !== 1 || cool_feedback_paginated) && sort !== "created_at") {
      return (
        <div className="big-top-padding">
          <NoticeBox type="info" text="Some Feedback from over 3 months old that you may expect to see given your current sort may not currently be visible. If you can't find what you are looking for, please try narrowing your search parameters, or try looking on other pages." />
        </div>
      )
    }
  }

  colourFeedbackByActionStatus(feedback) {
    switch(feedback.action_status) {
      case "Open":
        return "open-action"
      case "Started":
        return "started-action"
      case "Awaiting sign-off":
        return "awaiting-sign-off-action"
      case "Blocked":
        return "blocked-action"
      case "Completed":
        return "completed-action"
      case "Abandoned":
        return "abandoned-action"
      default:
        return "no-action"
    }
  }

  colourFeedbackByScore(feedback) {
    if (feedback.score === null) {
      return "scoreless-feedback"
    }
    if (feedback.score > 0) {
      return "positive-feedback"
    }
    if (feedback.score === 0) {
      return "neutral-feedback"
    }
    if (feedback.score < 0) {
      return "negative-feedback"
    }
  }

  determineOrder(firstEntry, secondEntry) {
    if (this.props.sort === "default") {
      return 0
    }

    if (this.props.sort === "created_at") {
      return datify(firstEntry.date) - datify(secondEntry.date)
    }
    let [parsedFirstEntry, parsedSecondEntry] = [firstEntry[this.props.sort], secondEntry[this.props.sort]];


    if (parsedFirstEntry > parsedSecondEntry) {
      return 1
    }

    if (parsedSecondEntry > parsedFirstEntry) {
      return -1
    }

    return 0
  }

  renderClickable(feedback) {
    return feedback.cool_feedback || feedback.type_of_person === "Anonymous" ? "not-clickable" : ""
  }

  render() {
    const { feedback, cool_feedback, cool_feedback_paginated, feedbackLoaded, coolFeedbackLoaded, error, unauthorized, invalid_params } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (invalid_params === true) {
      return <p>Waiting for valid date range ...</p>;
    }

    if (feedbackLoaded === false || coolFeedbackLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (feedbackLoaded && coolFeedbackLoaded) {
      let allFeedback = feedback.concat(cool_feedback)

      // only sort on render when cool_feedback is included
      if (cool_feedback.length > 0) {
        if (this.props.direction === "asc") {
          allFeedback = allFeedback.sort((firstEntry, secondEntry) => this.determineOrder(firstEntry, secondEntry));
        }
        else if (this.props.direction === "desc") {
          allFeedback = allFeedback.sort((firstEntry, secondEntry) => this.determineOrder(secondEntry, firstEntry));
        }
      }

      return (
        <div className="relative">
          {this.downloadCSV(allFeedback)}

          <div className="table big-table top-padding" data-testid="table">
            <div className="tr heading">
              {this.renderSortColumn("Question", "feedback_question_id")}
              {this.renderSortColumn("Answer", "score")}
              {this.renderSortColumn("Type", "type_of_person")}
              {this.renderSortColumn("Date", "created_at")}
              {this.renderSortColumn("Person", "person")}
              {this.renderSortColumn("Location", "location")}
              {this.renderSortColumn("Action Needed?", "action_needed")}
              {this.renderSortColumn("Action Status", "action_status")}
            </div>

            {allFeedback.map((feedback) => (
              <ConditionalLink url={`/exports/feedback/${feedback.id}`} condition={feedback.type_of_person !== "Anonymous" && feedback.cool_feedback === false} onClick={this.handleFeedbackActionsPanel} className={`tr event ${this.colourFeedbackByScore(feedback)} ${this.renderClickable(feedback)}`} key={feedback.id} id={feedback.id}>
                <div className="td">{feedback.question}</div>
                <div className="td">{feedback.answer}</div>
                <div className="td">{feedback.type_of_person}</div>
                <div className="td">{feedback.date}</div>
                <div className="td">{feedback.person}</div>
                <div className="td">{feedback.location}</div>
                <div className="td center">{feedback.action_needed ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimes} />}</div>
                <div className={`td center ${this.colourFeedbackByActionStatus(feedback)} ${this.renderClickable(feedback)}`}>{feedback.action_status}</div>
              </ConditionalLink>
            ))}
            </div>

          {this.emptyFeedback(allFeedback)}
          {this.renderPageButtons(this.props.page, feedback, cool_feedback_paginated)}
          {this.renderCoolFeedbackPaginationInfo(this.props.page, cool_feedback_paginated, this.props.sort)}

          <SlidingPane isOpen={this.state.feedbackActionsPanelToggle} title="Update Actions" width="60%"
            onRequestClose={this.closePanel}>
            <UpdateAction feedback_answer_id={this.state.feedbackId} closePanel={this.closePanel} />
          </SlidingPane>
        </div>
      );
    }
  }

  state = {
    feedback: [],
    cool_feedback: [],
    cool_feedback_paginated: false,
    error: null,
    unauthorized: null,
    invalid_params: false,

    feedbackLoaded: false,
    coolFeedbackLoaded: false,

    feedbackId: null,
    feedbackActionsPanelToggle: false
  };

  static getDerivedStateFromProps(props, state) {
    if (props.history.action === "POP" && props.id !== undefined) {
      return {
        feedbackActionsPanelToggle: true,
        feedbackId: props.id
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    // deep linking
    if (this.props.id !== undefined && this.state.feedbackActionsPanelToggle === false) {
      this.setState({
        feedbackId: this.props.id,
        feedbackActionsPanelToggle: true
      });
    }

    if (this.props.date_from !== "" && this.props.date_to !== "") {
      let modifiedDateFrom = new Date(this.props.date_from)
      modifiedDateFrom.setMonth(modifiedDateFrom.getMonth() + 3)
      modifiedDateFrom.setDate(modifiedDateFrom.getDate() - 3)

      let modifiedDateTo = new Date(this.props.date_to)
      modifiedDateTo.setMonth(modifiedDateTo.getMonth() + 3)
      modifiedDateTo.setDate(modifiedDateTo.getDate() + 3)

      const dateFromPastThreeMonths = modifiedDateFrom < Date.now();
      const dateToPastThreeMonths = modifiedDateTo < Date.now();

      var headers = new Headers();
      headers.append("Content-Type", "application/x-www-form-urlencoded");

      let params = `feedback[date_from]=${this.props.date_from}&feedback[date_to]=${this.props.date_to}&feedback[question]=${this.props.question}&feedback[location]=${this.props.selected_location}&feedback[person]=${this.props.type_of_person}&feedback[search]=${this.props.search}${arrayParamBuilder("feedback", "action_status", this.props.action_statuses)}&page=${this.props.page}&sort=${this.props.sort}&direction=${this.props.direction}`

      // only include action needed prop if it is true as we won't need to scope only to feedback answers which *don't* need follow up!
      if (this.props.action_needed) {
        params+= "&feedback[action_needed]=true"
      }

      var requestOptions = {
        method: 'GET',
        headers: headers,
        credentials: 'include',
        redirect: 'follow'
      };

      if (dateFromPastThreeMonths) {
        fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/exports/cool_feedback?${params}`, requestOptions)
          .then(response => {
            if (response.ok) {
              return response.json();
            }
            else if (response.status === 401) {
              this.setState({error: JSON.stringify(response.body)})
              this.setState({unauthorized: true})
            }
            else {
              throw new Error('Something went wrong ...');
            }
          })
          .then(data => {
            this.setState({ cool_feedback: data.feedback_answers || [], cool_feedback_paginated: data.further_page, coolFeedbackLoaded: true })
          })
          .catch(error => this.setState({ error, loaded: true }))
      }

      if (dateToPastThreeMonths === false) {
        fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/exports/feedback?${params}`, requestOptions)
          .then(response => {
            if (response.ok) {
              return response.json();
            }
            else if (response.status === 401) {
              this.setState({error: JSON.stringify(response.body)})
              this.setState({unauthorized: true})
            }
            else {
              throw new Error('Something went wrong ...');
            }
          })
          .then(data => {
            this.setState({ feedback: data, feedbackLoaded: true })
          })
          .catch(error => this.setState({ error, loaded: true }))
        }

      if (dateToPastThreeMonths) {
        // Feedback call has not triggered. Manually set the state and continue with just CoolFeedback
        this.setState({ feedback: [], feedbackLoaded: true })
      }

      if (dateFromPastThreeMonths === false) {
        // CoolFeedback call has not triggered. Manually set the state and continue with just Feedback
        this.setState({ cool_feedback: [], cool_feedback_paginated: false, coolFeedbackLoaded: true })
      }
    }
    else {
      this.setState({
        invalid_params: true
      })
    }
  }
}

export default withRouter(ExportFeedback);
