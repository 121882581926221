import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Header from '../../components/header/Header';

import FeedbackExport from '../../components/exports/Feedback';

import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';
import PopupBox from '../../components/shared/PopupBox';
import { debounce, optionalParam, buildParams, formatDate } from '../../utilities/Generic.js'

class ExportsFeedback extends Component {
  static TIME_COLUMNS = ["created_at"];

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleMultiCheckbox = this.handleMultiCheckbox.bind(this);
    this.handleSelectActionStatuses = this.handleSelectActionStatuses.bind(this);
    this.handlePreviousPage = this.handlePreviousPage.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handlePartialChange = this.handlePartialChange.bind(this);
    this.debouncedHandleSearch = debounce(this.handleSearch.bind(this), 500);
  }

  state = {
    date_from: null,
    date_to: null,
    max_date: formatDate(new Date(), 0),
    question: "",
    location: "",
    type_of_person: "",
    action_needed: false,
    action_statuses: [],
    page: 1,
    sort: "created_at",
    direction: "desc",
    selectActionStatuses: false,
    search: "",

    refresh_date_from: formatDate(new Date(), 7),
    refresh_date_to: formatDate(new Date(), 0),
    refresh_question: "",
    refresh_location: "",
    refresh_type_of_person: "",
    refresh_action_needed: false,
    refresh_action_statuses: [],
    refresh_page: 1,
    refresh_sort: "created_at",
    refresh_direction: "desc",
    refresh_search: "",

    questions: [],
    locations: [],
    questionsLoaded: false,
    locationsLoaded: false,
    key: 0
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({
      [name]: value,
      page: 1,
      key: Math.random()
    });

    let url = {
      date_from: this.state.date_from,
      date_to: this.state.date_to,
      question: this.state.question,
      location: this.state.location,
      type_of_person: this.state.type_of_person,
      action_needed: this.state.action_needed,
      action_statuses: this.state.action_statuses
    }

    url[name] = value

    this.props.history.push(
    );

    const params = buildParams(
      `from=${url.date_from}&to=${url.date_to}`,
      optionalParam("question", url.question),
      optionalParam("location", url.location),
      optionalParam("type", url.type_of_person),
      optionalParam("action_needed", url.action_needed),
      optionalParam("action_statuses", url.action_statuses),
      optionalParam("search", this.state.search),
      `page=1&sort=${this.state.sort}&direction=${this.state.direction}`
    )

    this.props.history.push(`/exports/feedback?${params}`);
  }

  handleCheckbox(event) {
    const name = event.target.name;
    const value = event.target.checked;

    this.setState({
      [name]: value,
      page: 1,
      key: Math.random()
    });

    const params = buildParams(
      `from=${this.state.date_from}&to=${this.state.date_to}`,
      optionalParam("question", this.state.question),
      optionalParam("location", this.state.location),
      optionalParam("type", this.state.type_of_person),
      optionalParam("action_needed", value),
      optionalParam("action_statuses", this.state.action_statuses),
      optionalParam("search", this.state.search),
      `page=1&sort=${this.state.sort}&direction=${this.state.direction}`
    )

    this.props.history.push(`/exports/feedback?${params}`);
  }

  handleMultiCheckbox(event) {
    const name = event.target.name;
    const value = event.target.checked;

    const action_statuses = this.state.action_statuses

    if (value === true) {
      if (!action_statuses.includes(name)) {
        action_statuses.push(name)
      }
    }
    else if (value === false) {
      if (action_statuses.includes(name)) {
        const index = action_statuses.indexOf(name)
        action_statuses.splice(index, 1)
      }
    }

    this.setState({
      action_statuses: action_statuses,
      page: 1,
      key: Math.random()
    });

    const params = buildParams(
      `from=${this.state.date_from}&to=${this.state.date_to}`,
      optionalParam("question", this.state.question),
      optionalParam("location", this.state.location),
      optionalParam("type", this.state.type_of_person),
      optionalParam("action_needed", this.state.action_needed),
      optionalParam("action_statuses", action_statuses),
      optionalParam("search", this.state.search),
      `page=1&sort=${this.state.sort}&direction=${this.state.direction}`
    )

    this.props.history.push(`/exports/feedback?${params}`);
  }

  handleSelectActionStatuses(event) {
    this.setState((prevState) => ({
      selectActionStatuses: !prevState.selectActionStatuses
    }))

    event.preventDefault();
  }

  handlePreviousPage() {
    let value = parseInt(this.state.page) - 1
    this.setState({
      page: value,
      key: Math.random()
    });

    const params = buildParams(
      `from=${this.state.date_from}&to=${this.state.date_to}`,
      optionalParam("question", this.state.question),
      optionalParam("location", this.state.location),
      optionalParam("type", this.state.type_of_person),
      optionalParam("action_needed", this.state.action_needed),
      optionalParam("action_statuses", this.state.action_statuses),
      optionalParam("search", this.state.search),
      `page=${value}&sort=${this.state.sort}&direction=${this.state.direction}`
    )

    this.props.history.push(`/exports/feedback?${params}`);
  }

  handleNextPage() {
    let value = parseInt(this.state.page) + 1
    this.setState({
      page: value,
      key: Math.random()
    });

    const params = buildParams(
      `from=${this.state.date_from}&to=${this.state.date_to}`,
      optionalParam("question", this.state.question),
      optionalParam("location", this.state.location),
      optionalParam("type", this.state.type_of_person),
      optionalParam("action_needed", this.state.action_needed),
      optionalParam("action_statuses", this.state.action_statuses),
      optionalParam("search", this.state.search),
      `page=${value}&sort=${this.state.sort}&direction=${this.state.direction}`
    )

    this.props.history.push(`/exports/feedback?${params}`);
  }

  handleSortChange(attribute) {
    let sort = this.state.sort
    let direction = this.state.direction

    if (this.state.sort !== attribute) {
      sort = attribute
      direction = ExportsFeedback.TIME_COLUMNS.includes(attribute) ? "desc" : "asc"
      this.setState({
        sort: attribute,
        direction: direction,
        page: 1,
        key: Math.random()
      });
    }
    else if (this.state.direction === "asc") {
      direction = "desc"
      this.setState({
        direction: direction,
        page: 1,
        key: Math.random()
      })
    }
    else {
      direction = "asc"
      this.setState({
        direction: direction,
        page: 1,
        key: Math.random()
      })
    }

    const params = buildParams(
      `from=${this.state.date_from}&to=${this.state.date_to}`,
      optionalParam("question", this.state.question),
      optionalParam("location", this.state.location),
      optionalParam("type", this.state.type_of_person),
      optionalParam("action_needed", this.state.action_needed),
      optionalParam("action_statuses", this.state.action_statuses),
      optionalParam("search", this.state.search),
      `page=1&sort=${sort}&direction=${direction}`
    )

    this.props.history.push(`/exports/feedback?${params}`);
  }

  handlePartialChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});

    this.debouncedHandleSearch()

    // This stops getDerivedStateFromProps using params to override this state change when typing immediately after hitting back/forward
    this.props.history.action = "PUSH"
  }

  handleSearch() {
    this.setState({
      page: 1,
      key: Math.random()
    })

    const params = buildParams(
      `from=${this.state.date_from}&to=${this.state.date_to}`,
      optionalParam("question", this.state.question),
      optionalParam("location", this.state.location),
      optionalParam("type", this.state.type_of_person),
      optionalParam("action_needed", this.state.action_needed),
      optionalParam("action_statuses", this.state.action_statuses),
      optionalParam("search", this.state.search),
      `page=1&sort=${this.state.sort}&direction=${this.state.direction}`
    )

    this.props.history.push(`/exports/feedback?${params}`);
  }

  render() {
    const { date_from, date_to, max_date, question, location, type_of_person, action_needed, action_statuses, selectActionStatuses, search, questions, locations, questionsLoaded, locationsLoaded, unauthorized, error, page, sort, direction } = this.state;
    const { match: { params } } = this.props;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (questionsLoaded === false || locationsLoaded === false) {
      return (
        <div>
          <SetTitle title={"Export Feedback | Exports"} />
          <PopupBox />
          <Header />

          <div className="main-page">
            <h2 className="page-title">Export Feedback</h2>
            <OrganisationName />

            <p>Loading ...</p>
          </div>
        </div>
      )
    }

    const baseLicense = localStorage.license === "base"

    return (
      <div>
        <SetTitle title={"Export Feedback | Exports"} />
        <PopupBox />
        <Header />

        <div className="main-page">
          <h2 className="page-title">Export Feedback</h2>
          <OrganisationName />

          <div className="export-selection">
            <div className="selection-block">
              <label className="column">Date from:</label>
              <input className="column" type="date" name="date_from" max={max_date} value={date_from} onChange={this.handleChange} />
            </div>

            <div className="selection-block">
              <label className="column">Date to:</label>
              <input className="column" type="date" name="date_to" max={max_date} value={date_to} onChange={this.handleChange} />
            </div>


            <br />
            <br />

            <div className="selection-block">
              <label className="column">Question:</label>
              <select name="question" value={question} onChange={this.handleChange} data-testid="select-questions">
                <option value="">All</option>
                {questions.map((question) => (
                  <option value={question.id} key={question.id}>{question.name}</option>
                ))}
              </select>
            </div>

            <div className="selection-block">
              <label className="column">Location:</label>
              <select name="location" value={location} onChange={this.handleChange} data-testid="select-locations">
                <option value="">All</option>
                {locations.map((location) => (
                  <option value={location.id} key={location.id}>{location.name}</option>
                ))}
              </select>
            </div>

            <div className="selection-block">
              <label className="column">Type:</label>
              <select name="type_of_person" value={type_of_person} onChange={this.handleChange} data-testid="select-type-of-person">
                <option value="">All</option>
                { baseLicense && <option value="anonymous">Anonymous</option> }
                <option value="visitor">Visitors</option>
                {baseLicense && (
                  <>
                    <option value="resident">Residents</option>
                    <option value="staff">Staff</option>
                    <option value="contractor">Contractors</option>
                    <option value="industry professional">Industry Professionals</option>
                    <option value="other">Other</option>
                  </>
                )}
              </select>
            </div>

            <div className="selection-block">
              <label className="column">Search by Name:</label>
              <input type="text" name="search" value={search} onChange={this.handlePartialChange} />
            </div>

            {baseLicense && (
              <>
                <div className="selection-block">
                  <input type="checkbox" id="action_needed" name="action_needed" checked={action_needed} onChange={this.handleCheckbox} />
                  <label htmlFor="action_needed">Only show action needed</label>
                </div>

                <div className="selection-block">
                  <div className="button" onClick={this.handleSelectActionStatuses}>Filter by Action Status</div>
                </div>

                <div className={selectActionStatuses ? "selection-block" : "hidden"}>
                  <div className="selection-block right-label">
                    <input type="checkbox" id="open" name="open" checked={action_statuses.includes("open")} onChange={this.handleMultiCheckbox} />
                    <label htmlFor="open">Open</label>
                  </div>

                  <div className="selection-block right-label">
                    <input type="checkbox" id="started" name="started" checked={action_statuses.includes("started")} onChange={this.handleMultiCheckbox} />
                    <label htmlFor="started">Started</label>
                  </div>

                  <div className="selection-block right-label">
                    <input type="checkbox" id="awaiting sign-off" name="awaiting sign-off" checked={action_statuses.includes("awaiting sign-off")} onChange={this.handleMultiCheckbox} />
                    <label htmlFor="awaiting sign-off">Awaiting sign-off</label>
                  </div>

                  <div className="selection-block right-label">
                    <input type="checkbox" id="blocked" name="blocked" checked={action_statuses.includes("blocked")} onChange={this.handleMultiCheckbox} />
                    <label htmlFor="blocked">Blocked</label>
                  </div>

                  <div className="selection-block right-label">
                    <input type="checkbox" id="completed" name="completed" checked={action_statuses.includes("completed")} onChange={this.handleMultiCheckbox} />
                    <label htmlFor="completed">Completed</label>
                  </div>

                  <div className="selection-block right-label">
                    <input type="checkbox" id="abandoned" name="abandoned" checked={action_statuses.includes("abandoned")} onChange={this.handleMultiCheckbox} />
                    <label htmlFor="abandoned">Abandoned</label>
                  </div>

                  <div className="selection-block right-label">
                    <input type="checkbox" id="n/a" name="n/a" checked={action_statuses.includes("n/a")} onChange={this.handleMultiCheckbox} />
                    <label htmlFor="n/a">n/a</label>
                  </div>
                </div>
              </>
            )}
          </div>

          <FeedbackExport date_from={date_from} date_to={date_to} question={question} selected_location={location} type_of_person={type_of_person} action_needed={action_needed} action_statuses={action_statuses} search={search} key={this.state.key + 1} id={params.id} page={page} handleNextPage={this.handleNextPage} handlePreviousPage={this.handlePreviousPage} sort={sort} direction={direction} handleSortChange={this.handleSortChange} />

        </div>

      </div>
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.search === "" && props.location.pathname.endsWith("/feedback")) {
      props.history.replace(`/exports/feedback?from=${state.refresh_date_from}&to=${state.refresh_date_to}&page=${state.refresh_page}&sort=${state.refresh_sort}&direction=${state.refresh_direction}`);

      return {
        date_from: state.refresh_date_from,
        date_to: state.refresh_date_to,
        question: state.refresh_question,
        location: state.refresh_location,
        type_of_person: state.refresh_type_of_person,
        action_needed: state.refresh_action_needed,
        action_statuses: state.refresh_action_statuses,
        search: state.refresh_search,
        page: state.refresh_page,
        sort: state.refresh_sort,
        direction: state.refresh_direction,
        key: Math.random()
      }
    }
    else {
      const params = new URLSearchParams(props.history.location.search)

      let date_from = params.get('from')
      let date_to = params.get('to')
      let question = params.get('question') || ""
      let location = params.get('location') || ""
      let type_of_person = params.get('type') || ""
      let action_needed = params.get('action_needed') === "true" ? true : false
      let action_statuses = params.get('action_statuses') === null ? [] : params.get('action_statuses').split(",")
      let selectActionStatuses = action_statuses.length > 0 ? true : state.selectActionStatuses;
      let search = params.get('search') || ""

      let page = params.get('page')
      let sort = params.get('sort')
      let direction = params.get('direction')

      // handle the outstanding feedback notification being clicked from within the export feedback page OR handle back/forward buttons
      if ((params.get('from') && params.get('from') !== state.date_from) || (props.history.action === "POP" && props.history.location.search !== "")) {
        return {
          date_from: date_from,
          date_to: date_to,
          question: question,
          location: location,
          type_of_person: type_of_person,
          action_needed: action_needed,
          action_statuses: action_statuses,
          selectActionStatuses: selectActionStatuses,
          search: search,
          page: page,
          sort: sort,
          direction: direction,
          key: Math.random()
        }
      }
      else {
        return null
      }
    }
  }

  componentDidMount() {
    // deep link params
    if (this.props.location.search !== "") {
      const params = new URLSearchParams(this.props.location.search)

      // grab query param or set default value
      const date_from = params.get('from') || formatDate(new Date(), 7);
      const date_to = params.get('to') || formatDate(new Date(), 0);
      const question = params.get('question') || "";
      const location = params.get('location') || "";
      const type_of_person = params.get('type') || "";
      const action_needed = params.get('action_needed') === "true" ? true : false;
      const action_statuses = params.get('action_statuses') === null ? [] : params.get('action_statuses').split(",");
      const search = params.get('search') || "";

      // automatically show action status selection boxes if any are deep linked
      const selectActionStatuses = action_statuses.length > 0 ? true : false;

      const page = params.get('page') || 1;
      const sort = params.get('sort') || "created_at";
      const direction = params.get('direction') || "desc";

      // set history in case one of the params was missing
      const builtParams = buildParams(
        `from=${date_from}&to=${date_to}`,
        optionalParam("question", question),
        optionalParam("location", location),
        optionalParam("type", type_of_person),
        optionalParam("action_needed", action_needed),
        optionalParam("action_statuses", action_statuses),
        optionalParam("search", search),
        `page=${page}&sort=${sort}&direction=${direction}`
      )

      this.props.history.replace(`/exports/feedback?${builtParams}`);

      this.setState({
        date_from: date_from,
        date_to: date_to,
        question: question,
        location: location,
        type_of_person: type_of_person,
        action_needed: action_needed,
        action_statuses: action_statuses,
        selectActionStatuses: selectActionStatuses,
        search: search,
        page: page,
        sort: sort,
        direction: direction,
        key: Math.random()
      });
    }

    // populate search boxes with default params when deep linking to the ViewEvent panel
    if (this.props.location.search === "" && !this.props.location.pathname.endsWith("/events")) {
      this.setState({
        date_from: this.state.refresh_date_from,
        date_to: this.state.refresh_date_to
      })
    }

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ locations: data, locationsLoaded: true })
      })
      .catch(error => this.setState({ error, locationsLoaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/feedback_questions/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ questions: data, questionsLoaded: true })
      })
      .catch(error => this.setState({ error, questionsLoaded: true }))
  }
}

export default ExportsFeedback;
