import React, { Component } from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';

import { request, reportErrorToHoneybadger } from '../../helpers/Requests'

import ViewEvent from '../../components/exports/ViewEvent';
import { arrayParamBuilder, datify, listWithGrammar, formatDate, renderSortColumn } from '../../utilities/Generic.js'
import { renderCheckOutWithContext } from '../../utilities/Events.js'

import { CSVLink } from "react-csv";

import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

class ExportVisitors extends Component {
  constructor(props) {
    super(props);

    this.handleEventPanel = this.handleEventPanel.bind(this);
    this.renderSortColumn = renderSortColumn.bind(this);
  }

  emptyVisitors(events) {
    if (events.length === 0) {
      return (
        <div className="long-tr">
          <div className="long-td">
            { this.emptyVisitorsText() }
          </div>
        </div>
      )
    }
  }

  emptyVisitorsText() {
    let text = "No visitors to show"

    if (this.props.page > 1) {
      text += " on this page"
    }

    let content = []
    this.props.selected_location !== "" && content.push(" Location")
    this.props.visited_type_of_person !== "" && content.push(" Visited Type")
    this.props.visitor_type_of_person !== "" && content.push(" Visitor Type")

    if (content.length > 0) {
      text += ` for this ${listWithGrammar(content)}`
    }

    let tags = []
    this.props.visited_tag_ids.length !== 0 && tags.push(" Visited Tags")
    this.props.visitor_tag_ids.length !== 0 && tags.push(" Visitor Tags")

    if (tags.length > 0) {
      text += ` with these ${listWithGrammar(tags)}`
    }

    let search = []
    this.props.visited_person_search.length !== 0 && search.push(" Visited Name Search")
    this.props.visitor_person_search.length !== 0 && search.push(" Visitor Name Search")

    if (search.length > 0) {
      text += ` using this ${listWithGrammar(search)}`
    }

    text += " in this time range."

    return text
  }

  customAttributeFinder(custom_attribute_id){
    return this.props.custom_attributes.find(custom_attribute => custom_attribute.id === custom_attribute_id)
  }

  personCustomAttributeFinder(person, custom_attribute_id){
    // returns empty object if person custom attribute is not available
    return person.custom_attributes.find(custom_attribute => custom_attribute.custom_attribute_id === custom_attribute_id) || {}
  }

  renderCustomAttributeHeaders() {
    let activeCustomAttributes = []

    this.props.custom_attribute_ids.forEach((custom_attribute_id) =>
      activeCustomAttributes.push(this.customAttributeFinder(custom_attribute_id))
    )

    return (
      <React.Fragment>
        {activeCustomAttributes.map((custom_attribute, index) => (
          <div className="th" key={"custom_attribute"+index}>{custom_attribute.name}</div>
        ))}
      </React.Fragment>
    )
  }

  renderPersonCustomAttributes(person) {
    return (
      <>
        {this.props.custom_attribute_ids.map((custom_attribute_id, index) => {
          const custom_attribute = this.personCustomAttributeFinder(person, custom_attribute_id)
          return (
            <div className="td" key={"custom_attribute"+index}>{custom_attribute.value}</div>
          )
        })}
      </>
    )
  }

  customAttributeCSV() {
    let array = []
    this.props.custom_attribute_ids.forEach((custom_attribute_id) => {
      const custom_attribute = this.customAttributeFinder(custom_attribute_id)

      array.push({ label: custom_attribute.name, key: custom_attribute.id.toString()})
    })
    return array
  }

  downloadCSV(events) {
    let filename = `visitors-export-${formatDate(new Date(), 0)}.csv`

    let headers = [
      { label: "Visited Name", key: "visited_person_name" },
      { label: "Visited Type", key: "visited_type_of_person" },
      { label: "Visitor Name", key: "visitor_person_name" },
      { label: "Visitor Type", key: "visitor_type_of_person" },
      { label: "Arrived", key: "check_in" },
      { label: "Left", key: "check_out" },
      { label: "Location", key: "location" }
    ];

    if (this.props.showDuration) {
      headers.splice(4, 0, { label: "Duration (hours)", key: "duration"})
    }

    headers = headers.concat(this.customAttributeCSV())

    // mapping custom attributes back into people using the custom_attribute_id as a key to match the keys in customAttributeCSV()
    events.forEach((event) => {
      event.custom_attributes.forEach((custom_attribute) => {
        event[custom_attribute.custom_attribute_id.toString()] = custom_attribute.value
      })
    })

    if (events.length === 0) {
      events = [{[headers[0].key]: this.emptyVisitorsText()}]
    }

    return (
      <CSVLink data={events} headers={headers} filename={filename} className="edit-button download-csv">
        <div>
          <FontAwesomeIcon icon={faFileDownload} />
          <h4>Download CSV</h4>
        </div>
      </CSVLink>
    )
  }

  handleEventPanel(event) {
    let id = event.currentTarget.id;

    this.setState({
      eventId: id,
      eventPanelToggle: true,
      previousURL: `${this.props.location.pathname}${this.props.location.search}`
    });
    this.props.history.push(`/exports/events/${id}`)
  }

  renderPageButtons(page, records) {
    if (parseInt(page) === 1 && records.length < 250) {
      return
    }
    else {
      return (
        <div>
          <button className="button new-button" onClick={this.props.handleNextPage} disabled={(records.length < 250)}>Next Page</button>
          <button className="button new-button" onClick={this.props.handlePreviousPage} disabled={(this.props.page < 2)}>Previous Page</button>
        </div>
      )
    }
  }

  renderVisitorRow(visit) {
    return (
      <Link to={`/exports/visitors/${visit.event_id}`} onClick={this.handleEventPanel} id={visit.event_id} key={visit.id} className="tr event">
        <div className="td">{visit.visited_person_name}</div>
        <div className="td">{visit.visited_type_of_person}</div>
        <div className="td">{visit.visitor_person_name}</div>
        <div className="td">{visit.visitor_type_of_person}</div>
        <div className="td">{visit.check_in}</div>
        { renderCheckOutWithContext(visit) }
        { this.props.showDuration && <div className="td">{visit.duration}</div>}
        <div className="td">{visit.location}</div>
        {this.renderPersonCustomAttributes(visit)}
      </Link>
    )
  }

  determineOrder(firstEntry, secondEntry) {
    if (this.props.sort === "default") {
      return 0
    }

    if (["check_in", "check_out"].includes(this.props.sort)) {
      return datify(firstEntry[this.props.sort]) - datify(secondEntry[this.props.sort])
    }
    let [parsedFirstEntry, parsedSecondEntry] = [firstEntry[this.props.sort], secondEntry[this.props.sort]];


    if (parsedFirstEntry > parsedSecondEntry) {
      return 1
    }

    if (parsedSecondEntry > parsedFirstEntry) {
      return -1
    }

    return 0
  }

  render() {
    const { visitors, visitorsLoaded, error, unauthorized, invalid_params } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (invalid_params === true) {
      return <p>Waiting for valid date range ...</p>;
    }

    if (visitorsLoaded === false) {
      return <p>Loading ...</p>;
    }

    return (
      <div className="relative">
        {this.downloadCSV(visitors)}

        <div className="table big-table top-padding" data-testid="table">
          <div className="tr heading">
            {this.renderSortColumn("Visited Name", "visited_person")}
            {this.renderSortColumn("Visited Type", "visited_type_of_person")}
            {this.renderSortColumn("Visitor Name", "visitor_person")}
            {this.renderSortColumn("Visitor Type", "visitor_type_of_person")}
            {this.renderSortColumn("Arrived", "check_in")}
            {this.renderSortColumn("Left", "check_out")}
            { this.props.showDuration && <div className="th">Duration (hours)</div> }
            {this.renderSortColumn("Location", "location")}
            {this.renderCustomAttributeHeaders()}
          </div>

          {visitors.map(visit => this.renderVisitorRow(visit))}
        </div>

        {this.emptyVisitors(visitors)}
        {this.renderPageButtons(this.props.page, visitors)}

        <SlidingPane isOpen={this.state.eventPanelToggle} title="View Event" width="60%"
          onRequestClose={() => {
            this.setState({ eventPanelToggle: false });
            this.setState({ eventId: null });
            this.props.history.push(this.state.previousURL);
            this.componentDidMount();
          }}>
          <ViewEvent id={this.state.eventId} />
        </SlidingPane>
      </div>
    );
  }

  state = {
    visitors: [],
    error: null,
    unauthorized: null,
    invalid_params: false,

    visitorsLoaded: false,

    eventId: null,
    eventPanelToggle: false,
    previousURL: "/exports/visitors"
  };

  static getDerivedStateFromProps(props, state) {
    if (props.history.action === "POP" && props.id !== undefined) {
      return {
        eventPanelToggle: true,
        eventId: props.id
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    // deep linking
    if (this.props.id !== undefined && this.state.eventPanelToggle === false) {
      this.setState({
        eventId: this.props.id,
        eventPanelToggle: true
      });
    }

    if (this.props.date_from !== "" && this.props.date_to !== "") {
      let params = `visitors[date_from]=${this.props.date_from}&visitors[date_to]=${this.props.date_to}&visitors[location]=${this.props.selected_location}&visitors[visited_person]=${this.props.visited_type_of_person}&visitors[visitor_person]=${this.props.visitor_type_of_person}${arrayParamBuilder("visitors", "visited_tag_ids", this.props.visited_tag_ids)}${arrayParamBuilder("visitors", "visitor_tag_ids", this.props.visitor_tag_ids)}${arrayParamBuilder("visitors", "custom_attribute_ids", this.props.custom_attribute_ids)}&visitors[visited_person_search]=${this.props.visited_person_search}&visitors[visitor_person_search]=${this.props.visitor_person_search}&page=${this.props.page}&sort=${this.props.sort}&direction=${this.props.direction}`

      request('GET', `/v1/a/exports/visitors?${params}`, null, () => {this.setState({ unauthorized: true })})
        .then(data => {
          this.setState({ visitors: data, visitorsLoaded: true })
        })
        .catch(error => {
          reportErrorToHoneybadger(error)

          this.setState({ error, visitorsLoaded: true })
        })
    }
    else {
      this.setState({
        invalid_params: true
      })
    }
  }
}

export default withRouter(ExportVisitors);
