import React, { Component } from 'react';
import { Redirect, withRouter} from 'react-router-dom';

import { CSVLink } from "react-csv";

import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { arrayParamBuilder, formatDate, renderSortColumn } from '../../utilities/Generic.js'

class ExportPeople extends Component {
  constructor(props) {
    super(props);

    this.renderSortColumn = renderSortColumn.bind(this);
  }

  emptyPeople(people) {
    if (people.length === 0) {
      return (
        <div className="long-tr">
          <div className="long-td">
            { this.emptyPeopleText() }
          </div>
        </div>
      )
    }
  }

  emptyPeopleText() {
    if (this.props.selected_location !== "" && this.props.type_of_person !== "" && this.props.page > 1) {
      return "No people to show on this page for this Location and Type."
    }
    else if (this.props.selected_location !== "" && this.props.type_of_person !== "") {
      return "No people to show for this Location and Type."
    }
    else if (this.props.selected_location !== "" && this.props.page > 1) {
      return "No people to show on this page for this Location."
    }
    else if (this.props.selected_location !== "") {
      return "No people to show for this Location."
    }
    else if (this.props.type_of_person !== "" && this.props.page > 1) {
      return "No people to show on this page for this Type."
    }
    else if (this.props.type_of_person !== "") {
      return "No people to show for this Type."
    }
    else if (this.props.page > 1) {
      return "No people to show on this page."
    }
    else {
      return "No people to show."
    }
  }

  customAttributeFinder(custom_attribute_id){
    return this.props.custom_attributes.find(custom_attribute => custom_attribute.id === custom_attribute_id)
  }

  personCustomAttributeFinder(person, custom_attribute_id){
    // returns empty object if person custom attribute is not available
    return person.custom_attributes.find(custom_attribute => custom_attribute.custom_attribute_id === custom_attribute_id) || {}
  }

  renderCustomAttributeHeaders() {
    let activeCustomAttributes = []

    this.props.custom_attribute_ids.forEach((custom_attribute_id) =>
      activeCustomAttributes.push(this.customAttributeFinder(custom_attribute_id))
    )

    return (
      <React.Fragment>
        {activeCustomAttributes.map((custom_attribute, index) => (
          <div className="th" key={"custom_attribute"+index}>{custom_attribute.name}</div>
        ))}
      </React.Fragment>
    )
  }

  renderPersonCustomAttributes(person) {
    return (
      <>
        {this.props.custom_attribute_ids.map((custom_attribute_id, index) => {
          const custom_attribute = this.personCustomAttributeFinder(person, custom_attribute_id)
          return (
            <div className="td" key={"custom_attribute"+index}>{custom_attribute.value}</div>
          )
        })}
      </>
    )
  }

  customAttributeCSV() {
    let array = []
    this.props.custom_attribute_ids.forEach((custom_attribute_id) => {
      const custom_attribute = this.customAttributeFinder(custom_attribute_id)

      array.push({ label: custom_attribute.name, key: custom_attribute.id.toString()})
    })
    return array
  }

  downloadCSV(people) {
    let filename = `people-export-${formatDate(new Date(), 0)}.csv`

    let headers = [
      { label: "First Name", key: "first_name" },
      { label: "Last Name", key: "last_name" },
      { label: "Email", key: "email" },
      { label: "Phone", key: "phone" },
      { label: "Type", key: "type_of_person" },
      { label: "Last Login", key: "last_login" },
      { label: "Last Updated At", key: "updated_at" },
      { label: "Number of Locations", key: "locations_count" },
      { label: "Number of Relationships", key: "relationships_count" },
      { label: "Covid Vaccination Pass Valid Until", key: "covid_pass_expiry_date" }
    ];

    headers = headers.concat(this.customAttributeCSV())

    // mapping custom attributes back into people using the custom_attribute_id as a key to match the keys in customAttributeCSV()
    people.forEach((person) => {
      person.custom_attributes.forEach((custom_attribute) => {
        person[custom_attribute.custom_attribute_id.toString()] = custom_attribute.value
      })
    })

    if (people.length === 0) {
      people = [{[headers[0].key]: this.emptyPeopleText()}]
    }

    return (
      <CSVLink data={people} headers={headers} filename={filename} className="edit-button download-csv">
        <div>
          <FontAwesomeIcon icon={faFileDownload} />
          <h4>Download CSV</h4>
        </div>
      </CSVLink>
    )
  }

  renderExpired(date) {
    const units = date.split("/")
    const day = units[0]
    const month = units[1]
    const year = units[2]

    const parsedDate = new Date(`${year}/${month}/${day}`)
    const currentDate = new Date().setHours(0,0,0,0)

    if (parsedDate < currentDate) {
      return (
        <div className="td red">{date}</div>
      )
    }
    else {
      return (
        <div className="td">{date}</div>
      )
    }
  }

  renderPageButtons(page, records) {
    if (parseInt(page) === 1 && records.length < 250) {
      return
    }
    else {
      return (
        <div>
          <button className="button new-button" onClick={this.props.handleNextPage} disabled={(records.length < 250)}>Next Page</button>
          <button className="button new-button" onClick={this.props.handlePreviousPage} disabled={(this.props.page < 2)}>Previous Page</button>
        </div>
      )
    }
  }

  render() {
    const { people, loaded, error, unauthorized, invalid_params } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (invalid_params === true) {
      return <p>Waiting for valid date range ...</p>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <div className="relative">
          {this.downloadCSV(people)}

          <div className="table big-table top-padding" data-testid="table">
            <div className="tr heading">
              {this.renderSortColumn("First name", "first_name")}
              {this.renderSortColumn("Last name", "last_name")}
              {this.renderSortColumn("Email", "email")}
              {this.renderSortColumn("Phone", "phone")}
              {this.renderSortColumn("Type", "type_of_person")}
              {this.renderSortColumn("Last Login", "last_login")}
              {this.renderSortColumn("Last Updated At", "updated_at")}
              <div className="th not-sortable">Number of Locations</div>
              <div className="th not-sortable">Number of Relationships</div>
              {this.renderSortColumn("Covid Vaccination Pass Valid Until", "covid_pass_expiry_date")}
              {this.renderCustomAttributeHeaders()}
            </div>

            {people.map((person, index) => (
              <div className="tr event" key={index}>
                <div className="td">{person.first_name}</div>
                <div className="td">{person.last_name}</div>
                <div className="td">{person.email}</div>
                <div className="td">{person.phone}</div>
                <div className="td">{person.type_of_person}</div>
                <div className="td">{person.last_login}</div>
                <div className="td">{person.updated_at}</div>
                <div className="td">{person.locations_count}</div>
                <div className="td">{person.relationships_count}</div>
                {this.renderExpired(person.covid_pass_expiry_date)}
                {this.renderPersonCustomAttributes(person)}
              </div>
            ))}
            </div>

          {this.emptyPeople(people)}
          {this.renderPageButtons(this.props.page, people)}
        </div>
      );
    }
  }

  state = {
    people: [],
    loaded: false,
    error: null,
    unauthorized: null,
    invalid_params: false
  };

  componentDidMount() {
    if (this.props.date_from !== "" && this.props.date_to !== "") {
      var headers = new Headers();
      headers.append("Content-Type", "application/x-www-form-urlencoded");

      let params = `people[location]=${this.props.selected_location}&people[person]=${this.props.type_of_person}${arrayParamBuilder("people", "tag_ids", this.props.tag_ids)}&people[search]=${this.props.search}&people[custom_attribute_search]=${this.props.custom_attribute_search}${arrayParamBuilder("people", "custom_attribute_ids", this.props.custom_attribute_ids)}&page=${this.props.page}&sort=${this.props.sort}&direction=${this.props.direction}`

      var requestOptions = {
        method: 'GET',
        headers: headers,
        credentials: 'include',
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/exports/people?${params}`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
            this.setState({error: JSON.stringify(response.body)})
            this.setState({unauthorized: true})
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data => {
          this.setState({ people: data, loaded: true })
        })
        .catch(error => this.setState({ error, loaded: true }))
    }
    else {
      this.setState({
        invalid_params: true
      })
    }
  }
}

export default withRouter(ExportPeople);
